import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindowF } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { Box, Col, Flex, Text, Wrapper } from '../../components/Ui';
import ReactSelect from 'react-select';
import styled, { useTheme } from 'styled-components/macro';
import ReactShadowScroll from 'react-shadow-scroll';
const libraries = ['places'];
const Map = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBZqcJgdHRsDBSexX8uu0RGFMTcNOl0teY',
    libraries,
  });

  const [markers, setMarkers] = useState([]);
  const [search, setSearch] = useState('');
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 41.8719, lng: 12.5674 });
  const [activePlace, setActivePlace] = useState(null);
  const theme = useTheme();
  const places = useSelector(state => state.app.places);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);

  useEffect(() => {
    setFilteredPlaces(places.filter(place => place.regione === search));
  }, [search]);

  console.log(filteredPlaces, places);

  const handleSearch = activeFiltered => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: activeFiltered }, (results, status) => {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        map.panTo(location);
        map.setZoom(10);
        setCenter({ lat: location.lat(), lng: location.lng() });
        setMarkers(prev => [...prev, { lat: location.lat(), lng: location.lng() }]);
      } else {
        setCenter({ lat: 41.8719, lng: 12.5674 });
        map.setZoom(5);
      }
    });
  };

  if (loadError) return <div>Error loading Google Maps: {loadError.message}</div>;
  if (!isLoaded) return <div>Loading...</div>;
  console.log({ activePlace });

  return (
    <Wrapper>
      <Flex row={15} align='stretch'>
        <Col width={75}>
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '600px' }}
            center={center}
            zoom={5}
            onLoad={mapInstance => setMap(mapInstance)}
            onClick={() => setActivePlace(null)}
          >
            {places.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
                onClick={() => setActivePlace(marker)}
              />
            ))}

            {activePlace && (
              <InfoWindowF
                position={{ lat: Number(activePlace.lat), lng: Number(activePlace.lng) }}
                onCloseClick={() => setActivePlace(null)}
              >
                <div>
                  <h3>Ditributtore Info</h3>
                  <p>
                    <strong>Latitude</strong>: {activePlace.lat}
                  </p>
                  <p>
                    <strong>Longitude</strong>: {activePlace.lng}
                  </p>
                  <p>
                    <strong>Store</strong>: {activePlace.store}
                  </p>
                </div>
              </InfoWindowF>
            )}
          </GoogleMap>
        </Col>

        <Col width={25}>
          <Box fullheight>
            <ReactSelectStyle
              className='basic-single'
              classNamePrefix='select'
              //isLoading={isLoading}
              isClearable={true}
              isSearchable={true}
              onChange={val => {
                setSearch(val ? val.value : '');
              }}
              value={search}
              name={'location'}
              options={places.map(place => ({
                label: place.regione,
                value: place.regione,
              }))}
              placeholder='Punto Vendita'
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  height: '50px',
                  borderRadius: '15px',
                  border: 'none',
                  backgroundColor: theme?.input?.bg,
                  borderRadius: theme?.border_radius_generale_campi,
                  fontSize: '14px',
                  fontWeight: '700',
                  padding: '0px 7px',
                }),
                dropdownIndicator: styles => ({
                  ...styles,
                  color: '#f00',
                }),
                placeholder: defaultStyles => {
                  return {
                    ...defaultStyles,
                    color: '#47545d59',
                    marginRight: '10px',
                  };
                },
              }}
            />
            <ReactShadowScroll style={{ height: '530px' }} shadow='none'>
              <ul>
                {filteredPlaces.map(place => (
                  <FilteredPlace
                    active={selectedPlace?.store === place.store}
                    key={place.store}
                    onClick={() => {
                      setSelectedPlace(place);
                      window.scrollTo(0, 0);
                      handleSearch(place.regione);
                    }}
                  >
                    {place.regione}
                  </FilteredPlace>
                ))}
              </ul>
            </ReactShadowScroll>
          </Box>
        </Col>
      </Flex>
    </Wrapper>
  );
};

const ReactSelectStyle = styled(ReactSelect)`
  width: 100%;
  border-radius: 15px;

  border: 1px solid
    ${props => {
      return (!props.isvalid && props.istouched && props.firstSubmit) ||
        (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.transparent
        ? 'transparent'
        : 'transparent';
    }};

  .select__indicators {
    display: none;
  }

  .select__control {
    box-shadow: none;
    &:hover {
      border: none;
    }
  }
`;

const FilteredPlace = styled.li`
  cursor: pointer;
  margin-top: 10px;
  padding: 10px;
  background-color: ${props => (props.active ? '#ddd' : '#f5f5f5')};
  padding-right: 5px;
  &:hover {
    background-color: #ddd;
  }
`;

export default Map;

import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondTo } from '../../theme/mixin';
import { Button, Flex } from '../Ui';
import Text from '../Ui/Text';
import WelfareCode from '../WelfareCode/WelfareCode';

const Container = styled.div`
  border: ${props => props.borderWidth || '1px'} solid ${props => props.border || 'transparent'};
  background-color: ${props => props.bgColor || ''};
  border-radius: ${props => props.radius || props.theme.border_radius_generale_grande};
  width: 100%;
  margin-bottom: ${props => props.marginBottom || '25px'};
  margin-top: ${props => props.marginTop || '0'};
  position: relative;
  top: ${props => props.top || '0'};
  overflow: hidden;
  height: ${({ moreCta }) => moreCta && 'fit-content'};
  ${respondTo.sm`
    width: ${({ width, gap }) => (+width === 100 ? '100%' : `calc(${width}% - ${gap || '1%'})`)};
  `}

  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ moreCta }) => (moreCta ? 'initial' : 'cover')};
  }
  .link {
    position: static;
    margin: 0 auto;
    margin-top: auto;

    button {
      padding: 0 70px;
      min-width: 230px;
    }
    ${respondTo.sm`
     
     ${({ position, moreCta }) =>
       position && !moreCta
         ? css`
             position: absolute;
             left: 50%;
             bottom: 30px;
             transform: translateX(-50%);
           `
         : css`
             position: static;
           `}
`}
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${({ bg, moreCta }) =>
    bg &&
    css`
      ${({ cancelMobile, moreCta }) =>
        cancelMobile && !moreCta
          ? css`
              position: absolute;
              top: 0;
              left: 0;
              border: none;
            `
          : css`
              position: static;
              border: 1px solid ${({ theme }) => theme.colore_bordo_box};
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border-top: none;
            `}
      ${respondTo.sm`
      position: ${moreCta ? 'static' : 'absolute'};
      top: 0;
      left: 0;
      border:none;
      
`}
    `}

  width: 100%;
  height: 100%;
  padding: ${props => props.padding || '40px'};
  padding-bottom: ${({ moreCta }) => moreCta && '30px'};
`;

function Notification({ bg, data, notif, promo, width }) {
  const style = notif && JSON.parse(notif?.style);
  const titlePromo = notif?.i18l.title ? notif?.i18l.title : notif?.promotion?.i18l?.title;
  const contentPromo = notif?.i18l.content
    ? notif?.i18l.content
    : notif?.promotion?.i18l?.description;
  const valueBtn = notif?.cta_label ? notif?.cta_label : 'PARTECIPA';
  const ctaUrl = notif?.cta_url?.split(';');
  const ctaLabel = notif?.cta_label?.split(';');

  return (
    <Container
      data={data}
      bg={bg}
      top={style?.top}
      bgColor={notif?.background_color}
      border={style?.border}
      radius={style?.radius}
      borderWidth={style?.borderWidth}
      marginBottom={style?.marginBottom}
      marginTop={style?.marginTop}
      width={width}
      gap={style?.gap}
      position={Boolean(notif?.img)}
      moreCta={ctaLabel?.length > 1}
    >
      {notif?.img && <img src={notif?.img} alt='' />}
      <Content bg={bg} padding={style?.padding} moreCta={ctaLabel?.length > 1}>
        <Text
          type='title'
          bold
          style={{ color: notif?.text_color }}
          align={style?.align || 'center'}
          width={style?.width}
        >
          {promo ? titlePromo : notif?.i18l.title}
        </Text>
        <Text as='p' width={style?.width} color={notif?.text_color} size={14} className='mb-30'>
          {promo ? contentPromo : notif?.i18l.content}
        </Text>
        {notif.type === 'welfare_code' && <WelfareCode />}
        {promo && (
          <Link to={`/promo/${notif.promotion.slug}`} className='link'>
            <Button active style={{ margin: '0 auto' }}>
              {valueBtn}
            </Button>
          </Link>
        )}
        {Boolean(notif?.show_cta) && (
          <Flex gap='30px' justify='center' className='link'>
            {ctaUrl.map((item, i) => (
              <Link to={item}>
                <Button active={i === 0} secondary={i !== 0}>
                  {ctaLabel[i]}
                </Button>
              </Link>
            ))}
          </Flex>
        )}
      </Content>
    </Container>
  );
}

export default Notification;

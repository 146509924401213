import React from 'react';
import { Button } from '../Ui';

/* const datass = [
  {
    label: 'COMO',
    children: [
      {
        label: 'PAPERINO',
        codice_cliente: 'C5',
        ragione_sociale: 'N B S  SPA',
        vatcode: '02895780175',
        details: {
          registered: false,
          individual_agreement_accepted: false,
          email: '',
          firstname: '',
          lastname: '',
          sections: [
            {
              label: 'OBIETTIVO',
              rows: [
                {
                  label: 'Obiettivo annuale',
                  value: '25960',
                },
                {
                  label: 'Progressivo',
                  value: '0',
                },
              ],
            },
            {
              label: 'PUNTI',
              rows: [
                {
                  label: 'Punti accumulati',
                  value: '0',
                },
                {
                  label: 'Punti spesi',
                  value: '0',
                },
                {
                  label: 'Punti disponibili',
                  value: '0',
                },
                {
                  label: 'Punti da confermare',
                  value: '0',
                },
              ],
            },
          ],
        },
      },
      {
        label: 'PAPERINO',
        codice_cliente: 'C6',
        ragione_sociale: 'RAMODORO S.R.L.',
        vatcode: '01005010119',
        details: {
          registered: false,
          individual_agreement_accepted: false,
          email: '',
          firstname: '',
          lastname: '',
          sections: [
            {
              label: 'OBIETTIVO',
              rows: [
                {
                  label: 'Obiettivo annuale',
                  value: '22776',
                },
                {
                  label: 'Progressivo',
                  value: '0',
                },
              ],
            },
            {
              label: 'PUNTI',
              rows: [
                {
                  label: 'Punti accumulati',
                  value: '0',
                },
                {
                  label: 'Punti spesi',
                  value: '0',
                },
                {
                  label: 'Punti disponibili',
                  value: '0',
                },
                {
                  label: 'Punti da confermare',
                  value: '0',
                },
              ],
            },
          ],
        },
      },
    ],
  },
]; */

const ExportToExcel = ({ hierarchicalData }) => {
  /*   const handleExport = () => {
    const flattenedData = flattenHierarchy(hierarchicalData); // Flatten the dataS
    generateExcelFile(flattenedData); // Generate and export Excel file
  }; */

  return (
    <Button active onClick={() => generateExcelFile(hierarchicalData)} className='mt-20'>
      Export
    </Button>
  );
};

export default ExportToExcel;
function getMaxDepth(data, depth = 0) {
  let maxDepth = depth;
  for (const item of data) {
    if (item.children && item.children.length > 0) {
      maxDepth = Math.max(maxDepth, getMaxDepth(item.children, depth + 1));
    }
  }
  return maxDepth;
}
const exportToExcelTable = (data, fileName) => {
  const rows = [];
  const dynamicLabels = [];
  const maxDepth = getMaxDepth(data);
  console.log({ maxDepth });

  // Step 1: Process dynamic labels (to be used in the table header)
  const processData = nodes => {
    nodes.forEach(node => {
      if (node.children) {
        processData(node.children); // Recursively process children
      } else {
        node.details.sections.forEach(section => {
          section.rows.forEach(row => {
            if (!dynamicLabels.includes(row.label)) {
              dynamicLabels.push(row.label);
            }
          });
        });
      }
    });
  };

  // First pass to collect dynamic labels
  processData(data);

  // Step 2: Create the header row (Area commerciale, Filiale, Agente, etc.)
  maxDepth === 1
    ? rows.push([
        'Filiale',
        'Agente',
        'Partita iva',
        'Ragione Sociale',
        'Email',
        'Nome',
        'Cognome',
        'Iscritto',
        'Singola Pattuizione',
        ...dynamicLabels, // Add dynamic labels as columns
      ])
    : rows.push([
        'Area commerciale',
        'Filiale',
        'Agente',
        'Partita iva',
        'Ragione Sociale',
        'Email',
        'Nome',
        'Cognome',
        'Iscritto',
        'Singola Pattuizione',
        ...dynamicLabels, // Add dynamic labels as columns
      ]);

  // Step 3: Process the data and populate rows
  const populateRows = (nodes, area = '', filiale = '') => {
    console.log({ nodes });
    nodes.forEach(node => {
      if (node.children) {
        console.log({ node });
        // Process the children, passing the labels from higher levels
        populateRows(node.children, area || '-', filiale || '-');
      } else {
        // Prepare row data for each "Agente"
        const rowData =
          maxDepth === 1
            ? [
                filiale || '-', // Filiale (e.g., COMO or MILANO)
                node.label || '-', // Agente (e.g., PAPERINO)
                node.vatcode || '-', // Codice cliente (if missing, '-')
                node.ragione_sociale || '-', // Ragione sociale (if missing, '-')
                node.details.email || '-', // Email (if missing, '-')
                node.details.firstname || '-', // Nome (if missing, '-')
                node.details.lastname || '-', // Cognome (if missing, '-')
                node.details.registered ? 'SI' : 'NO', // Iscritto (yes/no)
                node.details.individual_agreement_accepted ? 'SI' : 'NO', // Singola pattuizione (yes/no)
                ...Array(dynamicLabels.length).fill('-'), // Fill with '-' for dynamic labels
              ]
            : [
                area || '-', // Area commerciale (e.g., LOMBARDIA)
                filiale || '-', // Filiale (e.g., COMO or MILANO)
                node.label || '-', // Agente (e.g., PAPERINO)
                node.vatcode || '-', // Codice cliente (if missing, '-')
                node.ragione_sociale || '-', // Ragione sociale (if missing, '-')
                node.details.email || '-', // Email (if missing, '-')
                node.details.firstname || '-', // Nome (if missing, '-')
                node.details.lastname || '-', // Cognome (if missing, '-')
                node.details.registered ? 'SI' : 'NO', // Iscritto (yes/no)
                node.details.individual_agreement_accepted ? 'SI' : 'NO', // Singola pattuizione (yes/no)
                ...Array(dynamicLabels.length).fill('-'), // Fill with '-' for dynamic labels
              ];
        console.log('rowData:', rowData);

        // Step 4: Add dynamic label values to the row
        node.details.sections.forEach(section => {
          section.rows.forEach(row => {
            const index = dynamicLabels.indexOf(row.label);
            if (index !== -1) {
              rowData[10 + index] = row.value; // Set the value in the dynamic column
            }
          });
        });

        rows.push(rowData);
      }
    });
  };

  // Step 5: Loop through the data and start from the second level
  data.forEach(area => {
    // Skipping the first "ITALIA" level and start from the second level
    if (maxDepth === 1) {
      populateRows(area.children, '', area.label);
    } else if (area.children) {
      area.children.forEach(filiale => {
        if (filiale.children) {
          filiale.children.forEach(agente => {
            populateRows(
              [agente],
              maxDepth < 3 ? area.label : filiale.label,
              maxDepth < 3 ? filiale.label : agente.label,
            );
          });
        }
      });
    }
  });
  console.log({ rows, data, maxDepth });
  return rows;
  /*   // Step 6: Convert the rows to Excel and download it
  const worksheet = XLSX.utils.aoa_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, `${fileName}.xlsx`); */
};

const generateExcelFile = (data, fileName = 'singola_pattuizione_gerarchia.xls') => {
  const rows = exportToExcelTable(data);
  // Generate table headers
  const headers = rows[0];
  let tableContent = '<table><thead><tr>';
  headers.forEach(header => {
    tableContent += `<th>${header}</th>`;
  });
  tableContent += '</tr></thead><tbody>';

  // Generate table rows
  rows.slice(1).forEach(row => {
    tableContent += '<tr>';
    headers.forEach((header, index) => {
      // const key = header.toLowerCase().replace(' ', '_');
      tableContent += `<td>${row[index] || ''}</td>`;
    });
    tableContent += '</tr>';
  });
  tableContent += '</tbody></table>';

  // Create and download the Excel file
  const blob = new Blob([tableContent], { type: 'application/vnd.ms-excel' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};
